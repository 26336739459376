.mainWindow {
    height: 100vh;
    display: flex;
    // background-color: #ac87c52f;
    // justify-content: center;
    // align-items: center;
    // background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    // background-color:whitesmoke;
    .tiles {
        flex-grow: 1;
        display: flex;
         // justify-content: center;
        flex-wrap: wrap;
        padding: 2rem;
        // justify-content: space-evenly;
        gap: 2rem;
    }


}

// tab view

// @media screen and (max-width:1080px) {
//     .mainWindow {
//         .tiles {
//             flex-direction: column;
//         }

//     }
// }

// mobile view

// @media screen and (max-width:600px) {
//     .mainWindow {
//         .tiles {
//             flex-direction: column;
//         }
//     }
// }