.mainContent {
    padding: 8rem 18rem;
    background-color: #ac87c52f;

    .heading {
        font-size: 2.3rem;
        text-align: center;
        color: var(--blue);
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .para {
        text-align: justify;
        padding: 1rem 0;
    }

    .bulletPoints {
        font-weight: 600;
        font-size: 1.6rem;
        color: var(--blue);
    }

    .subheading {
        font-weight: 600;
        font-size: 1rem;
        color: var(--blue);
    }

    span {
        text-align: justify;
    }
}

// tab view

@media screen and (max-width:1080px) {
    .mainContent {
        padding: 8rem 4rem;
    }
}

// mobile view

@media screen and (max-width:600px) {
    .mainContent {
        padding: 8rem 1rem;
    }
}