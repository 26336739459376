.mainWindow {

    // display: flex;
    // flex-wrap: wrap;
    // background-color: #ac87c52f;

    .loading{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.17);
        z-index: 100;
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100px;
        }
    }


    .mainContent {
        display: flex;
        flex-wrap: wrap;
        // background-color: #f3ccff29;
        background-color: #ac87c52f;
        padding: 3rem 0;
        .section1 {
            width: 50%;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.7rem;
            padding: 0 4rem;
            margin-top: 6rem;

            .heading {
                font-size: 2.3rem;
                color: var(--blue);
                text-transform: capitalize;
                text-align: center;
                p {
                    color: var(--font-color);
                }

            }

            .userInfo {
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
                align-self: flex-start;
                width: 65%;

                .userInput {
                    display: flex;
                    flex-direction: column;
                    gap: .4rem;

                    input {
                        padding: .5rem;
                        border: none;
                        outline: none;
                        font-family: inherit;
                        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                    }

                }

                button {
                    padding: .5rem;
                    font-family: inherit;
                    background-color: var(--blue);
                    color: white;
                    border: 2px solid var(--blue);
                    margin-top: .5rem;
                    font-size: 1rem;
                    border-radius: 2px;
                    transition: all ease .2s;

                    &:hover {
                        background-color: white;
                        color: var(--blue);
                    }
                }
            }
        }

        .section2 {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            margin-top: 4rem;
            position: relative;

            img {
                width: 100%;
            }
        }
    }

}

// tab view

@media screen and (max-width:1080px) {
    .mainWindow {
        .mainContent {
            .section1 {
                padding: 0 3rem;
                width: 100%;
                min-height: unset;

                .userInfo {
                    align-self: center;
                }
            }

            .section2 {
                width: 100%;
            }
        }
    }
}

// mobile view

@media screen and (max-width:600px) {
    .mainWindow {
        .mainContent {
            .section1 {
                padding: 0 2rem;
                .userInfo {
                    width: 100%;
                }
            }
            .section2{
                min-height: unset;
                margin: 2rem 0;
            }
        }
    }
}