.loginPage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: aqua;
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);

    .glass {
        width: 97%;
        height: 97%;
        background-color: rgba(255, 255, 255, 0.54);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        flex-wrap: wrap;
        overflow-y: auto;
        padding: 3rem 0;
        transition: all ease .2s;


        .loading{
            position: fixed;
            z-index: 100;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.17);
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 100px;
            }
        }


        .modal {
            background-color: rgba(0, 0, 0, .17);
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 100;

            .optLogin {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 350px;
                height: 360px;
                border-radius: 4px;
                background-color: whitesmoke;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                // gap: 2.5rem;
                padding: 2rem 0;

                .otpInput {
                    width: 2.3rem;
                    height: 2.6rem;
                    text-align: center;
                    // border: 2px solid black;
                    border: 1px solid transparent;
                    border-radius: 3px;
                    // outline: 3px solid var(--blue);
                    font-size: 1.7rem;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                    margin-top: 3.5rem;
                    // width: 3rem;
                    &:focus {
                        outline: 1.5px solid var(--blue);
                        box-shadow: #D0A2F7 0px 3px 8px;
                    }

                    // &:active{
                    //     border: 2px solid red;
                    // }
                }
                button{
                    background-color: var(--blue);
                    border: 2px solid var(--blue);
                    width: 60%;
                    color: white;
                    font-family: inherit;
                    padding: .5rem;
                    margin-top: 3.5rem;
                }
            }
        }

        .testA,
        .testB {
            width: 200px;
            height: 200px;
            position: absolute;
            z-index: 1;
            filter: blur(100px);
        }

        .testA {
            background-color: aquamarine;
            top: 10%;
            right: 10%;
        }

        .testB {
            background-color: coral;
            bottom: 10%;
            left: 10%;
        }

        .adminLogin,
        .hrLogin,
        .digitalMarketing {
            width: 200px;
            height: 200px;
            background-color: whitesmoke;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            transition: all ease .2s;
            z-index: 2;

            &:hover {
                scale: 1.1;
            }

            .loginLogo {
                font-size: 2.5rem;
            }
        }

        .adminLogin {
            .properAlign {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .alignCenter {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .hrLogin {
            .alignCenter {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .digitalMarketing {
            .alignCenter {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .loginCard {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 1rem;
            // height: 30rem;
            // width: 150%;
            // width: 20rem;
            // height: 20rem;
            // transform: translate(-50%,-50%);
            z-index: 2;

            input {
                padding: .5rem;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                border: none;
                outline: none;
            }

            button {
                padding: .3rem 0;
                border: 1px solid var(--blue);
                background-color: var(--blue);
                color: white;
                font-family: inherit;
            }
        }

        .openModal {
            width: 20rem;
            height: 54%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            background-color: whitesmoke;
            padding: 1rem;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            transition: all ease .2s;
            z-index: 2;

            .alignCenter {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

// tab view

@media screen and (max-width : 1080px) {
    .loginPage {
        .glass {
            .openModal {
                height: 300px;
            }
        }
    }
}
