main {
    max-width: 100%;
    overflow: hidden;
    // background-color: #f3ccff29;
    background-color: #ac87c52f;
    .section1 {
        padding: 8rem 2rem;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0;
        div {
            width: 50%;
            padding: 0 2rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            height: fit-content;

            img {
                margin: auto;
                display: block;
                width: 300px;
                height: auto;
                // margin: 0;
            }

            h1 {
                color: var(--blue);
                text-align: center;
            }

            p {
                text-align: justify;
            }

            ul {
                list-style-position: inside;

                li {
                    margin-top: .6rem;
                }
            }

            &::nth-child(2) {
                padding: 0 2rem;
            }
        }

    }

    .carasoul {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        // margin: auto;
        // display: flex;
        // gap: 4rem;
        // align-items: center;
        // img{
        //     height: 8rem;
        // }
        // animation: car 15s linear infinite;
        p {
            text-transform: capitalize;
            text-align: center;
            font-weight: 600;
            font-size: 2.3rem;
            color: var(--blue);
        }
        .solutionPartner{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            // justify-content: center;
            align-items: center;
            justify-content: center;
            // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            width: 30%;
            padding: 2rem 0;
            margin: auto;
            .solutionIcon{
                font-size: 3.5rem;
                color: var(--green);
            }
            .sp{
                font-size: 2rem;
                text-transform: uppercase;
                color: var(--green);
            }
        }
        .testimonial{
            .images{
                display: flex;
                justify-content: center;
                img{
                    width: 40%;
                    padding-bottom: 3rem;
                }
            }
        }
    }

    .section2 {
        display: flex;
        padding: 2rem;

        .mdIntro{
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: relative;
            img{
                border-radius: 6px;
                // border-radius: 50%;
                // width: 300px;
                // width: 80%;
                width: 88%;
                // aspect-ratio: 1/1;
                object-fit: cover;
                object-position: top;
            }
            .bgBlue{
                text-align: center;
                    background-color: var(--blue);
                    color: white;
                    padding: .5rem;
                    font-weight: 500;
            }
            p{
                

                // right: 0;
                &:nth-child(1){
                    position: absolute;
                    bottom: 0;
                    transform: translate(-50%,-65%);
                    // right: 2rem;
                    right: -95px;
                    // left: 0;
                    font-weight: 600;
                    color: white;
                    font-size: 2rem;
                    text-align: center;
                    span{
                        font-weight: 400;
                        color: white;
                    }
                }
                &:nth-child(2){
                    text-align: center;
                    background-color: var(--blue);
                    color: white;
                    padding: .5rem;
                    font-weight: 500;
                }
            }

        }

        div {
            width: 50%;
            padding: 4rem 2rem;

            ul {
                list-style-position: inside;

                li {
                    margin-top: 2rem;
                    text-align: justify;

                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

// animations
@keyframes car {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

// Tab view
@media screen and (max-width:1080px) {
    main {
        .section1 {
            flex-direction: column;
            gap: 5rem;

            div {
                width: 100%;
            }
        }

        .section2 {
            flex-direction: column;
            .mdIntro{
                img{
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }
            }
            div {
                width: 100%;
            }
        }
    }
}

// mobile view
@media screen and (max-width:600px) {
    main {
        .section1 {
            padding: 8rem 1rem;
            padding-bottom: 0;

            div {
                padding: 0 .5rem;
            }
        }

        .section2 {
            padding: 5rem 1rem;
            gap: 3rem;
            .mdIntro{
                img{
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }
            }

            div {
                padding: 0 .5rem;
            }
        }
    }
}