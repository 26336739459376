.applicationTable{
    padding-top: 5rem;
    .filterCandidates{
        margin: auto;
        margin-left: 270px;
        margin-top: 2rem;
        width: 90%;
        display: flex;
        gap: 2rem;
        margin-bottom: 3rem;
        .applyFilter{
            display: flex;
            flex-direction: column;
            gap: .8rem;
            width: 20%;
            select{
                width: 100%;
                padding: .3rem;
                border-radius: 2px;
                outline: none;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                font-family: inherit;
                option{
                    border-radius: 0 !important;
                    &:nth-child(odd){
                        background-color: rgba(211, 211, 211, 0.589);
                    }
                    &:hover{
                        background-color: lightgray;
                    }
                }
            }
        }
        .applyBtn{
            align-self: flex-end;
            padding: .5rem;
            width: 10rem;
            background-color: var(--blue);
            color: white;
            border: 2px solid var(--blue);
        }
    }   
    table {
        
        border-collapse: collapse;
        width: 80%;
        overflow-x: auto;
        margin-left: 270px;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
        thead {
            overflow-x: auto;
            tr {
                th {
                    background-color:whitesmoke;
                    overflow: hidden;
                    font-weight: 500;
                    text-align: left;
                    &:nth-child(1) {
                        overflow: hidden;
                    }

                    padding: .3rem;
                }
            }
        }
        tbody {
            overflow-x: auto;
            tr {
                color: var(--font-color);
            }
        }
    }
}