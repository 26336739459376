.section1 {
    padding: 8rem 4rem;
    // background-color: whitesmoke;
    // background-color: #f3ccff29;
    background-color: #ac87c52f;
    h1 {
        text-transform: capitalize;
        color: var(--blue);
        font-size: 2.3rem;
        font-weight: 300;
        align-self: flex-start;
    }

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .applicationForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 2rem 0;
        row-gap: 2rem;
        width: 90%;
        justify-self: center;

        .input {
            display: flex;
            flex-direction: column;
            width: 45%;
            gap: .8rem;
            select{
                border: none;
                outline: none;
                padding: .5rem;
                font-family: inherit;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.774);
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            }
            input {
                border: none;
                outline: none;
                padding: .5rem;
                font-family: inherit;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.774);
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                &::-webkit-inner-spin-button{
                    display: none;
                }
                &::-webkit-outer-spin-button{
                    display: none;
                }
            }

        }

        .Fileinput {
            display: flex;
            flex-direction: column;
            width: 45%;
            gap: .8rem;

            input {
                border: none;
                outline: none;
                font-family: inherit;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.774);
                background-color: white;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

                &::-webkit-file-upload-button {
                    padding: .5rem;
                    color: white;
                    background-color: var(--blue);
                    font-family: inherit;
                    border: none;
                }
            }
        }
    }

    button {
        display: block;
        margin: auto;
        font-family: inherit;
        width: 30%;
        padding: .7rem;
        background-color: var(--blue);
        color: white;
        border: 2px solid var(--blue);
        transition: .3s;
        font-size: 1.3rem;
        margin-top: 4rem;

        &:hover {
            background-color: white;
            color: var(--blue);
        }
    }
}

//  Tab view

@media screen and (max-width:1080px) {
    // .section1 {
    //     padding: 6rem 1.5rem;

    //     .applicationForm {
    //         flex-direction: column;
    //         width: 100%;

    //         .input {
    //             width: 95%;
    //         }
    //     }

    //     button {
    //         margin-top: 2rem;
    //         width: 50%;
    //     }
    // }
}

// Mobile view

@media screen and (max-width:600px) {
    .section1 {
        padding: 6rem 1.5rem;

        h1 {
            font-size: 2rem;
            margin-top: 3rem;
        }

        .applicationForm {
            flex-direction: column;
            width: 100%;

            .input {
                width: 95%;
            }
            .Fileinput{
                width: 95%;
            }
        }

        button {
            margin-top: 2rem;
            width: 50%;
        }
    }
}