.mainNav {
    width: 250px;
    height: 100vh;
    background-color: #111827;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    position: fixed;
    .topSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
            width: 100px;
        }

        .dashboard {
            color: white;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .logoutSection {
        display: flex;
        align-items: center;
        gap: .7rem;
        font-weight: 600;
        color: white;
    }
}

// mobile view
@media screen and (max-width:600px) {
    .mainNav{
        width: 150px;
        .topSection{
            img{
                width: 80px;
            }
        }
    }
    
}