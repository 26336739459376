.mainWindow {
    padding: 8rem 4rem;
    background-color: #ac87c52f;
    .testA {
        position: fixed;
        width: 200px;
        aspect-ratio: 1/1;
        top: 20%;
        right: 10%;
        background-color: aquamarine;
        filter: blur(100px);
        // z-index: 1;
    }

    p {
        font-size: 2.3rem;
        font-weight: 500;
        color: var(--blue);
        text-align: center;
    }

    .cards {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        padding: 0 2rem;
        gap: 3rem;

        // z-index: 2;
        .card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 3rem;
            padding: 1rem;
            border-radius: 4px;
            background-color: white;
            z-index: 1;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

            p {
                text-align: left;
                font-weight: 400;
            }

            img {
                max-width: 250px;
                max-height: 250px;
                aspect-ratio: 1/1;
                border-radius: 50%;
                object-fit: cover;
                // object-position: top;
            }

            .memberInfo {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1rem 0;
            }
        }
    }
}


@media screen and (max-width:1080px) {
    .mainWindow{
        padding-left: 1rem;
        padding-right: 1rem;
        .cards{
            .card{
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                .memberInfo{
                    p{
                        &:nth-child(1){
                            text-align: center;
                        }
                        &:nth-child(2){
                            text-align: justify;
                        }
                    }
                }
              
            }
        }
    }
}


//  mobiule view
@media screen and (max-width:600px) {
    .mainWindow{
        padding-left: 1rem;
        padding-right: 1rem;
        .cards{
            padding: 0;
        }
    }
}