main {
    // background-color: #f3ccff29;
    background-color: rgba(172, 135, 197, 0.14);

    .topSection {
        width: 100vw;
        height: 120vh;
        // min-height: 100vh;
        // height:max-content;
        // background-image: url('../../assets/new.jpg');
        background-image: url('https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply');
        background-size: cover;
        animation: bg 400ms ease-in 1;
        animation-range: scroll();
        // background-repeat: no-repeat;
        position: relative;

        .glass {
            width: 700px;
            height: 400px;
            background-color: rgba(255, 255, 255, 0.148);
            backdrop-filter: blur(10px);
            border-radius: 4px;
            color: white;
            padding: 1rem;
            // position: absolute;
            // top: 45%;
            // left: 20%;
            // transform: translate(-30%,-30%);
        }
    }

    .section1 {
        display: flex;
        min-height: 100vh;

        div {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                margin-top: 3rem;
                width: 70%;
                // clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
                // animation: homepage-image 1.3s linear infinite;
            }
        }

        .homepageText {
            // width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 3.8rem;
            gap: 2.6rem;

            h1 {
                font-size: 2.8rem;
                font-weight: 400;

                span {
                    color: var(--green);
                }
            }

            p {
                font-weight: 400;
                font-size: 1.7rem;
            }

            button {
                font-family: inherit;
                width: 55%;
                padding: .6rem;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 500;
                color: white;
                background-color: var(--blue);
                // border: 2x solid var(--blue);
                border: 2px solid transparent;
                transition: all ease .3s;

                &:active {
                    scale: .9;
                }

                a {
                    text-decoration: none;
                    color: white;
                }

                &:hover {
                    a {
                        color: var(--blue);
                    }

                    background-color: transparent;
                    border: 2px solid var(--blue);

                }
            }
        }



    }

    // testimonial
    .carasoul {
        p {
            color: var(--blue);
            font-size: 2.5rem;
            font-weight: 600;
            text-align: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        .testimonail {
            .images {
                display: flex;
                justify-content: center;

                img {
                    width: 40%;
                    padding-bottom: 3rem;
                }
            }
        }
    }


    .section2 {
        display: flex;
        min-height: 100vh;

        div {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 80%;
            }
        }

        .homepage2Text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 3.8rem;
            gap: 2.6rem;

            h1 {
                font-size: 2rem;
                font-weight: 400;

                span {
                    color: var(--green);
                }
            }

            p {
                font-weight: 400;
                text-align: justify;
                font-size: 1.3rem;
            }
        }
    }

    .section3 {
        display: flex;
        min-height: 100vh;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;

            img {
                width: 60%;
            }
        }

        .homepage3text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2rem;
            padding: 0 4rem;

            h1 {
                color: var(--blue);
                font-weight: 300;
                font-size: 3rem;
                text-align: left;
                padding: 0;
            }

            ul {
                list-style-position: inside;

                li {
                    padding: .6rem 0;
                    font-size: 1.3rem;
                    text-align: justify;
                }
            }
        }
    }
}

// .homepageWindow {
//     animation: landing .7s linear;
//     margin: auto;
// }

// Animations 

@keyframes bg {
    from {
        opacity: .9;
    }

    to {
        opacity: 1;
    }
}

@keyframes landing {
    from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes homepage-image {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(1rem);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-1rem);
    }

    100% {
        transform: translateY(0);
    }
}

// 13inch laptop 

@media screen and (max-width:1400px) {
    main{
        .section1{
            .homepageText{
                padding: 9rem 3.8rem;
            }
        }
    }
}

// Tab View

@media screen and (max-width : 1080px) {
    main {
        .section1 {
            flex-direction: column;

            div {
                width: 100%;
            }

            .homepageText {
                margin-top: 10rem;
                padding: var(--mobile-view-padding);
                gap: 2rem;
                align-items: center;

                h1 {
                    font-size: 2.9rem;
                    text-align: center;
                }

                p {
                    font-size: 1.9rem;
                    text-align: center;
                }

                button {
                    font-weight: 600;
                    padding: .9rem;
                    font-size: 1rem;
                    width: 70%;
                }
            }

            .carasoul {
                p {
                    font-size: 2rem;
                    font-weight: 500;
                    padding: 0 .3rem;
                }
            }
        }


        .section2 {
            flex-direction: column-reverse;
            gap: 4rem;

            div {
                width: 100%;

                img {
                    width: 75%;
                }
            }

            .homepage2Text {
                padding: 0 2.5rem;
                gap: 4rem;

                h1 {
                    text-align: justify;
                }

                p {
                    text-align: justify;
                }
            }
        }

        .section3 {
            flex-direction: column;

            div {
                width: 100%;
            }

            .homepage3Text {
                padding: 0 1rem;

                h1 {
                    font-size: 1.4rem;
                }
            }
        }
    }
}


// mobile view

@media screen and (max-width:600px) {
    main {
        .section1 {
            flex-direction: column;

            div {
                width: 100%;
            }

            .homepageText {
                margin-top: 7rem;
                padding: var(--mobile-view-padding);
                gap: 2rem;

                h1 {
                    font-size: 1.7rem;
                }

                p {
                    font-size: 1.4rem;
                }

                button {
                    font-weight: 600;
                    padding: .4rem;
                    font-size: 1rem;
                    width: 100%;
                }
            }
        }

        .section2 {
            gap: 2.5rem;

            div {
                img {
                    width: 90%;
                }
            }

            .homepage2Text {
                padding: 0 1rem;
                margin-top: 4rem;
                gap: 2.5rem;

                h1 {
                    font-size: 1.6rem;
                    text-align: justify;
                }

                p {
                    font-size: 1.4rem;
                    font-weight: 300;
                    text-align: center;

                }
            }
        }

        .section3 {
            flex-direction: column;
            gap: 3rem;

            div {
                width: 100%;

                img {
                    width: 80%;
                }
            }

            .homepage3text {
                margin-top: 4rem;
                padding: 0 1rem;

                h1 {
                    font-size: 1.9rem;
                }

                ul {
                    li {
                        font-size: 1.1rem;
                    }

                }
            }
        }
    }
}