.mainWindow {
    width: 100%;
    height: 100vh;
    display: flex;
    // justify-content: center;
    // align-items: center;
    // background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    background-color: snow;



    .sections {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-wrap: wrap;
        margin-left: 250px;
        width: 100%;
        .leftSection {
            width: 50%;
            margin-top: 3rem;

            p {
                margin-bottom: 1rem;
                text-align: left;
            }

            .content {
                display: flex;
                // justify-content: center;
                // align-items: center;
                height: 84%;

                .collectData {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    gap: 1.7rem;

                    .dataInput {
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;

                        input {
                            padding: .5rem;
                            border: none;
                            outline: none;
                            font-family: inherit;
                            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                        }
                    }

                    button {
                        padding: .5rem;
                        background-color: var(--blue);
                        border: 2px solid var(--blue);
                        color: white;
                        font-family: inherit;
                        font-size: 1rem;
                    }
                }
            }
        }

        .rightSection {
            width: 50%;
            margin-top: 3rem;

            table {
                border-collapse: collapse;
                width: 90%;
                overflow-x: auto;

                thead {
                    background-color: #96e6a1;

                    tr {
                        th {
                            font-weight: 400;
                            padding: .5rem;
                        }
                    }
                }

                tbody {
                    tr {
                        background-color: var(--selected);

                        td {
                            text-align: center;
                        }

                        button {
                            background-color: var(--rejected);
                            color: white;
                            border-radius: 10px;
                            padding: .2rem;
                            border: none;
                            border: 1px solid var(--rejected);
                            width: 70%;
                        }
                    }
                }
            }
        }
    }


}

// tab view

@media screen and (max-width:1080px) {
    .mainWindow {
        .sections {
            margin-left: 150px;
            .leftSection {
                width: 100%;
            }

            .rightSection {
                // margin: auto;
                display: flex;
                // justify-content: center;
                overflow-x: auto;
                width: 80%;
            }
        }

        // .content{
        //     .collectData{
        //         width: 50%;
        //     }
        // }

    }
}

// mobile view

@media screen and (max-width:600px) {
    .mainWindow {

        .sections {
            margin-left: 120px;
            .leftSection {
                width: 90%;

                .content {
                    .collectData {
                        width: 90%;
                    }
                }

                p {
                    margin-bottom: 1rem;
                }
            }
            .rightSection{
                width: 90%;
            }
        }

        // .content{
        //     .collectData{
        //         width: 80%;
        //     }

    }
}