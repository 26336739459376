.mainWindow{
    // min-height: 100vh;
    display: flex;
    .mainContent{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        p{
            text-align: center;
            color: var(--blue);
            font-weight: 500;
            font-size: 2.3rem;
            margin-top: 2rem;
        }
        table{
            align-self: center;
            justify-self: center;
            border-collapse: collapse;
            width: 50%;
            margin-top: 2rem;
            thead{
                background-color: var(--selected);
                color:black;
            }
        }
    }
}