.mainWindow {
    .mainSection {
        padding: 8rem 4rem;
        // background-color: #f3ccff29;
        // background-color: #f3ccff29;
        background-color: #ac87c52f;

        .modal {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, .67);
            z-index: 200;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-y: auto;

            .imagePreviewer {
                max-width: 600px;
                position: relative;

                .close {
                    position: absolute;
                    top: .6rem;
                    right: .6rem;
                    color: aliceblue;
                    size: 3rem;
                }

                img {
                    width: 100%;
                    max-height: 500px;
                    object-fit: cover;
                }
            }

        }

        .gallery {
            span {
                color: var(--blue);
                font-weight: 400;
                font-size: 2.3rem;
                position: relative;
                padding-bottom: .3rem;
            }

            .galleryEvents {
                display: flex;
                gap: 2rem;
                flex-wrap: wrap;
                .bookreading {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    img {
                        width: 250px;
                        height: 250px;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                .reading {
                    position: fixed;
                    background-color: rgba(0, 0, 0, .17);
                    width: 100vw;
                    height: 100vh;
                    top: 0;
                    left: 0;
                    z-index: 100;

                    .bookMainModal {
                        width: 80%;
                        height: 85%;
                        background-color: white;
                        border-radius: 4px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;

                        .bookHeader {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 1rem;
                            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                            .bookHeading {
                                font-weight: 500;
                                font-size: 1.8rem;
                            }

                            .bookClose {
                                align-self: flex-start;
                                font-size: 1.4rem;

                                &:hover {
                                    color: var(--blue);
                                }
                            }
                        }

                        .pics {
                            padding: .6rem .3rem;
                            display: flex;
                            flex-wrap: wrap;
                            gap: .2rem;
                            overflow-y: auto;

                            .pic {
                                width: 197px;
                                height: 270px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }

                .rangmanch {
                    img{
                        width: 250px;
                        height: 250px;
                        object-fit:cover;
                        object-position:center;
                    }
                }
            }
        }

        .newsSection {
            span {
                color: var(--blue);
                font-weight: 400;
                font-size: 2.3rem;
                position: relative;
                padding-bottom: .3rem;
            }
            .coming{
                font-size: .8rem;
                color: #FF6969;
            }
        }

        .companyEvents {
            span {
                color: var(--blue);
                font-weight: 400;
                font-size: 2.3rem;
                position: relative;
                padding-bottom: .3rem;
            }
            .coming{
                font-size: .8rem;
                color: #FF6969;
            }
        }
    }
}

// tab view

@media screen and (max-width:1080px) {
    .mainWindow {
        .mainSection {
            padding: 8rem 2rem;

            .gallery {
                .reading {
                    .bookMainModal {
                        .pics {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}