.mainWindow{
    .mainContent{
        padding: 8rem 19rem;
        background-color: #f3ccff29;
        .cards{
            display: flex;
            .card{

            }
        }
        .firstBlog{
            .blogsHeading{
                font-size: 2.3rem;
                color: #6ECB63;
                font-weight: 500;
                text-align: center;
                display: block;
            }
            .blogPara{
                margin: 1.5rem 0;
                text-align: justify;
            }
            p{
                text-align: justify;
            }
            .blogsSubHeading{
                font-size: 1.7rem;
                font-weight: 500;
                margin-top: 2rem;
                color: var(--blue);
                display: block;
            }
            .blogsList{
                font-weight: 700;
                font-size: 1.1rem;
                display: block;
                color: var(--blue);
                margin: 2rem 0;
                margin-bottom: 1rem;
            }
        }
    }
}

// Tab view

@media screen and (max-width:1080px) {
    .mainWindow{
        .mainContent{
            padding: 8rem;
        }
    }
}


@media screen and (max-width:600px) {
    .mainWindow{
        .mainContent{
            padding: 8rem .8rem;
        }
    }
}

