.pending {
    background-color: var(--pending);
}

.partialApproved{
    background-color: var(--partially-selected);
}

.rejected {
    background-color: var(--rejected);
    color: white;
}

.approved {
    background-color: var(--selected);
}
td{
    text-transform: uppercase;
    padding: .2rem;
    select{
        border: none;
        padding: .1rem .2rem;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    button{
        border: none;
        padding: .1rem .2rem;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        text-align: center;
        align-items: center;
        width: 100%;
    }
}