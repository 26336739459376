.tile {
    width: 380px;
    min-height: 340px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: .7rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    img {
        width: 35%;
        align-self: center;
    }

    .role {
        text-align: center;
        color: var(--blue);
        font-size: 1.5rem;
    }
    p{
        text-transform: capitalize;
    }
    a{
        text-align: center;
        margin-right: 1rem;
        button {
            padding: .7rem 0;
            font-family: inherit;
            background-color: var(--blue);
            color: white;
            font-size: 1rem;
            border: 2px solid var(--blue);
            width: 40%;
            align-self: center;
            transition: .3s;
            // justify-self: center;
            &:hover {
                background-color: white;
                color: var(--blue);
            }
        }
    }

    .links{
        // display: flex;
        // gap: 1rem;
        // flex-direction: row;
        
    }
   
    .dis {
        cursor: not-allowed;

        &:hover {
            opacity: .5;
        }
    }
}

// mobile view

@media screen and (max-width:600px) {
    .tile{
        width: 350px;
        a{
            &:nth-child(1){
                button{
                    width: 30%;
                }
            }
            &:nth-child(2){
                button{
                    width: 50%;
                }
            }

        }
    }
}